import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "../../lib/src";
import { DocPageMeta, HeaderSection, ContentSection, Header } from "../docs";

export const Meta: DocPageMeta = {
	category: "Designing",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Icons FAQ"
			subTitle="Answers to frequently asked questions about icons."
			hideNav
		/>
		<ContentSection>
			<TextContainer article>
				<Header.H2 id="i-need-an-icon">
					I need an icon. How do I check whether it exists in Fancy?
				</Header.H2>
				<Paragraph>
					The <GatsbyLink to="/lib/components/Visuals/Icons Overview">Icons Overview</GatsbyLink>{" "}
					page is the single source of truth when it comes to Fancy icons. The icons on this page
					are a direct reflection of the icons available in code. We try to keep the{" "}
					<GatsbyLink to="/Designing/figma-FAQ">Fancy UI kit for Figma</GatsbyLink>
					in sync with the code. However, it may sometimes lag behind a bit.
				</Paragraph>
				<Header.H2>How do I hand over a Fancy icon to a developer?</Header.H2>
				<Paragraph>
					If the icon exists in Fancy, your developer will only need to know its name. The names of
					the icons in the <GatsbyLink to="/Designing/figma-FAQ">Fancy UI kit for Figma</GatsbyLink>{" "}
					match the names used in code. So, your developer can find the name of the icon in question
					by inspecting your mockup.
				</Paragraph>
				<Header.H2 id="cant-find-the-icon">
					I can't find the icon I need in Fancy. What should I do?
				</Header.H2>
				<Paragraph>
					First check{" "}
					<a href="https://fonts.google.com/icons?selected=Material+Icons">
						Google's Material Design icon library
					</a>
					. If it's not there, try <a href="https://icons8.com/">Icons8</a> (login and password can
					be found on{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/spaces/DESIGN/overview">Confluence</a>
					).
				</Paragraph>
				<Paragraph>
					Use the "filled" theme as much as possible. This will keep our icon system visually
					consistent. The "outlined" theme can be used if the "filled" variant is too visually
					heavy. It's also okay to use the "outlined" theme when you need to communicate the
					opposite state of an element (e.g. a filled star means something is favoritted, whereas an
					outlined star means it's not).
				</Paragraph>
				<Header.H2 id="help-it-doesnt-exist">
					The icon I need also doesn't exist in the icon libraries of Google's Material Design or
					Icons8. What should I do?
				</Header.H2>
				<Paragraph>
					You'll need to create it yourself. If you've found an icon that resembles what you're
					looking for, you can tweak it in <GatsbyLink to="/Designing/figma-FAQ">Figma</GatsbyLink>{" "}
					to suit your needs. If you’re not that lucky, you’ll need to create the icon from scratch.
					Follow Google’s Material Design{" "}
					<a href="https://material.io/design/iconography/system-icons.html#design-principles">
						icon design principles
					</a>{" "}
					to make sure the icon will look like our other icons. The most important guidelines to
					keep in mind are:
				</Paragraph>
				<Ul
					items={[
						"Design the icon on an artboard that is 24 by 24 pixels.",
						"Reserve space between your icon and the borders of the artboard. For instance, squares should be 18 by 18 pixels, circles should be 20 by 20 pixels, horizontal rectangles should be 16 by 20 pixels and vertical rectangles should be 20 by 16 pixels.",
						"Use simple geometric shapes as much as possible.",
						"Avoid using multiple colors.",
						"Use 2 pixel corner radiuses. Interior corners should be square, not rounded. For shapes 2 pixels wide or less, stroke corners shouldn’t be rounded.",
						"Use a stroke width of 2 pixels.",
						"Use squared stroke terminals.",
					]}
				/>
				<Header.H2 id="new-icon-what-should-i-do">
					I've found or created a new icon. What should I do?
				</Header.H2>
				<Paragraph>
					Send it to <a href="https://app.slack.com/client/T02Q1BM7T/CAVM2RC4F">#ux_fancy</a> Slack
					channel. We'll make sure it's visually aligned with the rest of the collection and add it
					Fancy's code and UI Kit. We will notify you when this work is done. You can then hand to
					your developers simply by referencing the name it's been given in{" "}
					<GatsbyLink to="/lib/components/Visuals/Icons Overview">Fancy</GatsbyLink>.
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);
